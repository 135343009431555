import '../scss/main.scss'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from '../js/templates/App.vue'
import router from '../js/router/router'
import store from '../js/store/store'
import { Message, MessageBox, Notification } from 'element-ui'
import VueCoreVideoPlayer from 'vue-core-video-player'

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification

Vue.use(Vuelidate)
Vue.use(VueCoreVideoPlayer)

/**
 * For more information about the specific options
 * @url https://vuejs.org/v2/api/
 *
 * During development you want this to be true, so that you are aware that the build includes warnings
 * @bool false Vue.config.productionTip
 *
 * During development you want this to be true, so that you can use Developer Tools
 * @bool true Vue.config.devtools
 *
 * During development you want this to be false, so that Vue gives you hints about your code
 * @bool true Vue.config.silent
 *
 * Set this to true to enable component init, compile, render and patch performance tracing in the browser devtool performance/timeline panel.
 * Only works in development mode and in browsers that support the performance.mark API.
 * @bool false Vue.config.performance
 */

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false
  Vue.config.devtools = false
  Vue.config.silent = true
  Vue.config.performance = false
} else {
  Vue.config.productionTip = `false`
  Vue.config.devtools = true
  Vue.config.silent = false
  Vue.config.performance = false
}

/* eslint-disable no-new */
new Vue({
  store,
  router,
  el: '#vueApp',
  components: {
    App
  },
  template: `<App />`
})
