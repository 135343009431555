/* istanbul ignore file */
// 1. Define route components.
const Home = () => import('../views/Home.vue')

// 2. Define some routes
const routes = [
  {
    name: 'home',
    path: '/',
    component: Home
  }
]

export default routes
