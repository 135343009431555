<template>
  <footer class="footer">
    <div class="o-container u-txt-c">
      <a href="https://www.jdi.nl" class="footer__link" target="_blank" title="JDI smart web applications">Development: JDI smart web applications</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 5px 0;
  width: 100%;
  background-color: $color--jacarta;

  &__link {
    color: $background--primary;
    text-decoration: none;
  }

  @include iota-breakpoint(md) {
    padding: 10px 0;
  }
}
</style>
