var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "o-container u-txt-c" }, [
        _c(
          "a",
          {
            staticClass: "footer__link",
            attrs: {
              href: "https://www.jdi.nl",
              target: "_blank",
              title: "JDI smart web applications"
            }
          },
          [_vm._v("Development: JDI smart web applications")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }