<template>
  <header>
    <div class="o-container u-txt-c">
      <img src="../../assets/images/logo.svg" />
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style lang="scss" scoped>
header {
  img {
    max-width: 250px;

    @include iota-breakpoint(sm) {
      max-width: 300px;
    }

    @include iota-breakpoint(md) {
      max-width: 350px;
    }
  }

  padding: 1.5rem 0;
  @include iota-breakpoint(md) {
    padding: 3rem 0;
  }
}
</style>
